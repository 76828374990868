<template>
  <div>
    <div
      v-if="questions.length > 0"
      class="card bg-warning text-white text-center rounded shadow-md px-5 py-3 floating-question d-flex justify-content-center align-items-center"
    >
      <div class="mt-4">
        <span class="font-weight-bolder font-size-md" style="font-size: 1rem">
          Soal Terpilih
        </span>
        <p class="font-weight-bolder" style="font-size: 2rem">
          {{ questions.length }}
        </p>
      </div>
    </div>

    <a
      @click="$router.back()"
      href="#"
      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
    >
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <validation-observer ref="formPaket">
      <div class="card card-custom card-stretch">
        <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark">
              Buat Paket Soal
            </span>
          </h3>
          <div class="card-toolbar">
            <button
              type="button"
              @click="submitForm"
              class="btn btn-success font-weight-bolder font-size-sm mr-2"
            >
              Simpan
            </button>
            <span
              @click="$router.back()"
              class="btn btn-secondary font-weight-bolder font-size-sm"
            >
              Batal</span
            >
          </div>
        </div>
        <div class="card-body pt-4">
          <b-form-group
            id="input-group-1"
            label="Nama Paket:"
            label-for="input-1"
          >
            <validation-provider
              #default="{ errors }"
              name="Nama Paket"
              rules="required"
            >
              <div
                class="spinner spinner-primary spinner-left"
                v-if="isLoading"
              >
                <input class="form-control mb-5" />
              </div>
              <b-form-input
                v-if="!isLoading"
                :state="errors.length > 0 ? false : null"
                id="input-1"
                v-model="currentForm.name"
                type="text"
                placeholder="Masukkan Nama Paket"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Kategori Paket:"
            label-for="input-1"
          >
            <validation-provider
              #default="{ errors }"
              name="Kategori Paket"
              rules="required"
            >
              <div
                class="spinner spinner-primary spinner-left"
                v-if="isLoading"
              >
                <input class="form-control mb-5" />
              </div>
              <b-form-select
                id="per-page-select"
                v-model="currentForm.exam_categories"
                :options="getCustomFirstExamOptions('Pilih Kategori Paket')"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="this.currentForm.exam_categories"
            class="mr-1 w-25"
            id="input-group-1"
            label="Topik Soal:"
            label-for="input-1"
          >
            <div class="spinner spinner-primary spinner-left" v-if="isLoading">
              <input class="form-control mb-5" />
            </div>
            <vue-select
              taggable
              multiple
              push-tags
              placeholder="Pilih Topik Soal"
              v-model="questionTopics"
              :options="questionTopicsOptions"
            />
          </b-form-group>
        </div>
      </div>
    </validation-observer>
    <br />
    <template v-if="!isDetail">
      <div class="card card-custom card-stretch">
        <div class="card-header border-0 py-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark">
              Bank Soal
            </span>
          </h3>
        </div>
        <div class="card-body pt-4">
          <b-row class="justify-content-between">
            <b-col cols="auto">
              <b-form-group>
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="auto">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
            :filter="filter"
            :items="currentBankSoalList.data"
            :fields="fields"
            :busy="isLoading"
            @filtered="onFiltered"
            show-empty
            responsive
          >
            <!--              tbody-tr-class="card card-table-row card-custom card-stretch gutter-b"-->
            <template #empty="scope">
              <h6 class="text-center">Tidak ada soal ditemukan</h6>
            </template>
            <template #emptyfiltered="scope">
              <h6 class="text-center">Tidak ada soal ditemukan</h6>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(index)="row">
              {{ perPage * (currentPage - 1) + (row.index + 1) }}
            </template>
            <template #row-details="row">
              <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                Soal:
              </div>
              <div v-html="row.item.question"></div>
              <div
                class="text-dark-75 font-weight-bolder mb-1 font-size-sm"
                v-if="!row.item.showOnlyQuestion"
              >
                Jawaban:
                <div class="jawaban">
                  <div
                    :class="`card card-custom card-stretch border shadow-none`"
                    v-for="(jawaban, i) in row.item.answer"
                    :key="`${i}`"
                  >
                    <div
                      class="card-body d-flex flex-row p-4 justify-content-between align-items-center"
                    >
                      <div v-html="jawaban.answer"></div>
                      <div>{{ jawaban.point }} pts</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(soal)="data">
              <div
                v-html="data.item.question"
                style="
                  max-width: 200px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                "
              ></div>
            </template>
            <template #cell(kode_soal)="data">
              {{ data.item.quetion_code }}
            </template>
            <template #cell(tipe)="data">
              <span
                class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
              >
                {{ data.item.type }}
              </span>
            </template>
            <template #cell(bobot)="data">
              <!--          <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
              <!--            {{ topik }}-->
              <!--          </b-badge>-->
              <span
                class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
              >
                {{ data.item.bobot }} pts
              </span>
            </template>
            <template #cell(topic)="data">
              <span
                class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
                v-for="topik in data.item.topic"
                :key="topik"
              >
                {{ topik }}
              </span>
            </template>
            <template #cell(action)="data">
              <div class="d-flex justify-content-end">
                <span
                  @click="toggleQuestion(data)"
                  class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
                >
                  <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                  <i
                    :class="`ki ki-bold-arrow-${
                      data.detailsShowing ? 'up' : 'down mt-1'
                    } icon-sm`"
                  ></i>
                </span>
                <span
                  @click="toggleAnswer(data)"
                  class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
                  >Daftar Jawaban</span
                >
                <span
                  @click="chooseSoal(data.item)"
                  :class="`btn ${
                    isExist(data.item._id) ? 'btn-warning' : 'btn-info'
                  } font-weight-bolder font-size-sm
                mr-1`"
                >
                  {{ isExist(data.item._id) ? "Soal Dipilih" : "Pilih Soal" }}
                </span>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
    </template>
    <template v-if="isDetail">
      <PaketDetail />
    </template>
    <b-modal
      v-model="modalJawaban"
      centered
      scrollable
      title="Pilihan Jawaban"
      hide-footer
    >
      <div class="jawaban">
        <div
          :class="`card card-custom card-stretch border ${
            jawaban.isCorrect
              ? 'bg-light-primary text-primary border-primary'
              : ''
          }`"
          v-for="(jawaban, i) in selectedSoal.answer"
          :key="`${selectedSoal.id}${i}`"
        >
          <div
            class="card-body d-flex flex-row p-4 justify-content-between align-items-center"
          >
            <div v-html="jawaban.answer"></div>
            <div v-if="jawaban.point && jawaban.point > 0">
              {{ jawaban.point }} pts
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import Swal from "sweetalert2";

import { ValidationObserver, ValidationProvider } from "vee-validate";

import { GET_BANK_SOAL } from "@/core/services/store/banksoal.module";
import {
  STORE_PAKET,
  UPDATE_PAKET,
  GET_ONE_PAKET,
  SELECTED_TOPICS,
} from "@/core/services/store/paket.module";
import { GET_TOPIC } from "@/core/services/store/soal.module";
import PaketDetail from "@/view/pages/paket/PaketDetail.vue";

import { globalMixin } from "../../../core/mixin/globalMixin";

export default {
  name: "SiswaForm",
  mixins: [globalMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    PaketDetail,
  },
  data() {
    return {
      currentForm: {
        name: "",
        exam_categories: null,
      },
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      selectedSoal: {},
      perPage: 10,
      selectedMapel: null,
      modalJawaban: false,
      modalBankSoal: false,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "question_code",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "type",
          label: "Tipe Soal",
          sortable: true,
        },
        {
          key: "topic",
          label: "Topik Soal",
          sortable: true,
        },
        {
          class: "text-center",
          key: "action",
          label: "Aksi",
          sortable: false,
          thStyle: { width: "10px" },
        },
      ],
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      questions: [],
      questionTopics: [],
      questionTopicsOptions: [],
    };
  },
  beforeMount() {
    if (!this.idQuestion) {
      this.getTopic();
    }
  },
  mounted() {
    this.getData();

    if (this.idQuestion) {
      this.getDetail();
    }
  },
  watch: {
    perPage: function () {
      this.getData();
    },
    currentPage: function () {
      this.getData();
    },
    "currentForm.exam_categories"(newVal) {
      this.currentForm.exam_type = this.examOptions.filter(
        (x) => x.value == newVal
      )[0].exam_type;
      this.getData();
    },
    questionTopics: function (newVal) {
      if (!this.idQuestion) {
        this.getData();
      } else {
        this.$store.dispatch(
          SELECTED_TOPICS,
          JSON.parse(JSON.stringify(newVal))
        );
      }
    },
  },
  methods: {
    isExist: function (item) {
      if (this.questions.indexOf(item) !== -1) {
        return true;
      }

      return false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    submitForm() {
      if (!this.idQuestion) {
        this.currentForm.questions = this.questions;
      } else {
        this.currentForm.id = this.idQuestion;
      }

      this.$store
        .dispatch(
          this.idQuestion ? UPDATE_PAKET : STORE_PAKET,
          this.currentForm
        )
        .then((response) => {
          Swal.fire({
            title: response.message,
            text: `Berhasil Membuat Paket Soal`,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.$router.push(`/paket-soal`);
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
          this.$bvToast.toast(e.data.message, {
            title: `Gagal Membuat Paket Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getData() {
      this.isLoading = true;
      const param = new URLSearchParams();
      param.append("limit", this.perPage);
      param.append("page", this.currentPage);
      param.append(
        "where['exam_categories']",
        this.currentForm.exam_categories
      );
      param.append("topic", this.questionTopics);

      this.$store
        .dispatch(GET_BANK_SOAL, param.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentBankSoalList.total;
          this.currentBankSoalList.data.forEach((row) => {
            this.$nextTick(() => {
              this.$set(row, "_showDetails", true);
              this.$set(row, "showOnlyQuestion", true);
            });
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    onReset() {},

    viewJawaban(item) {
      this.selectedSoal = item;
      this.modalJawaban = true;
    },
    chooseSoal(item) {
      if (this.questions.includes(item._id)) {
        this.questions.splice(this.questions.indexOf(item._id), 1);
      } else {
        this.questions.push(item._id);
      }
    },
    getDetail() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_PAKET, this.idQuestion)
        .then((response) => {
          this.isLoading = false;

          this.currentForm.name = response.data.name;
          this.currentForm.exam_categories = response.data.exam_categories;
          if (response.data.questions) {
            let optionsTopics = [];
            response.data.questions.map((x) => {
              let topics = JSON.parse(JSON.stringify(x.topic));
              topics.map((el) => optionsTopics.push(el));
            });

            const uniqueSet = new Set(optionsTopics);
            this.questionTopicsOptions = [...uniqueSet];
          }

          // this.questionTopicsOptions = response.data.
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getTopic() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_TOPIC, {})
        .then((data) => {
          this.isLoading = false;
          this.questionTopicsOptions = data.data;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$bvToast.toast(err, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    toggleQuestion(data) {
      this.$nextTick(() => {
        if (!data.item.showOnlyQuestion) {
          this.$set(data.item, "_showDetails", true);
        } else {
          this.$set(data.item, "_showDetails", !data.item._showDetails);
        }
        this.$set(data.item, "showOnlyQuestion", true);
      });
    },
    toggleAnswer(data) {
      this.selectedSoal = data.item;
      this.$nextTick(() => {
        if (data.item.showOnlyQuestion) {
          this.$set(data.item, "showOnlyQuestion", false);
        } else {
          this.$set(data.item, "showOnlyQuestion", true);
        }
      });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters(["currentPaket"]),
    isDetail() {
      if (this.$route.params.idQuestion !== undefined) {
        return true;
      }
      return false;
    },
    idQuestion() {
      return this.$route.params.idQuestion;
    },
    currentBankSoalList() {
      let data = this.$store.getters.currentBankSoalList;
      return data;
    },
  },
};
</script>

<style scoped>
/deep/ .vs__dropdown-toggle {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 5px;
  border: 1px solid #e4e6ef;
}

.floating-question {
  z-index: 100;
  position: fixed;
  top: 10px;
  right: 10px;
  width: 150px;
  height: 80px;
}
</style>
