<template>
  <div>
    <div
      v-if="questions.length > 0 || newQuestions.length > 0"
      class="card bg-warning text-white text-center rounded shadow-md px-5 py-3 floating-question d-flex justify-content-center align-items-center"
    >
      <div class="mt-4">
        <span class="font-weight-bolder font-size-md" style="font-size: 1rem">
          Soal Terpilih
        </span>
        <p class="font-weight-bolder" style="font-size: 2rem">
          {{ questions.length > 0 ? questions.length : newQuestions.length }}
        </p>
      </div>
    </div>
    <div class="card card-custom card-stretch">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Bank Soal
          </span>
        </h3>
        <div class="card-toolbar">
          <span
            v-if="questions.length == 0"
            @click="openModal"
            class="btn btn-primary font-weight-bolder font-size-sm"
          >
            Tambah</span
          >
          <button
            v-if="questions.length > 0"
            @click="removeQuestion"
            type="button"
            class="btn btn-danger font-weight-bolder font-size-sm mr-2"
          >
            Hapus
          </button>
          <span
            v-if="questions.length > 0"
            @click="resetSelected"
            class="btn btn-secondary font-weight-bolder font-size-sm"
          >
            Batal</span
          >
        </div>
      </div>
      <div class="card-body pt-4">
        <b-row class="justify-content-between">
          <b-col cols="auto">
            <b-form-group>
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="auto">
            <b-form-group
              label="Filter"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Clear</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
          :filter="filter"
          :items="
            currentPaket.questions !== undefined
              ? currentSelectedTopic != null && currentSelectedTopic.length > 0
                ? currentPaket.questions.filter((x) => {
                    var filter = false;
                    x.topic.map((item) => {
                      if (currentSelectedTopic == null) filter = true;
                      else {
                        currentSelectedTopic.map((el) => {
                          if (item == el) filter = true;
                        });
                      }
                    });
                    return filter;
                  })
                : currentPaket.questions
              : []
          "
          :current-page="currentPage"
          :per-page="perPage"
          :fields="fields"
          :busy="isLoading"
          @filtered="onFiltered"
          show-empty
          responsive
        >
          <!--              tbody-tr-class="card card-table-row card-custom card-stretch gutter-b"-->
          <template #empty="scope">
            <h6 class="text-center">Tidak ada soal ditemukan</h6>
          </template>
          <template #emptyfiltered="scope">
            <h6 class="text-center">Tidak ada soal ditemukan</h6>
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(index)="row">
            {{ perPage * (currentPage - 1) + (row.index + 1) }}
          </template>
          <template #row-details="row">
            <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
              Soal:
            </div>
            <div v-html="row.item.question"></div>
            <div
              class="text-dark-75 font-weight-bolder mb-1 font-size-sm"
              v-if="!row.item.showOnlyQuestion"
            >
              Jawaban:
              <div class="jawaban">
                <div
                  :class="`card card-custom card-stretch border shadow-none`"
                  v-for="(jawaban, i) in row.item.answer"
                  :key="`${i}`"
                >
                  <div
                    class="card-body d-flex flex-row p-4 justify-content-between align-items-center"
                  >
                    <div v-html="jawaban.answer"></div>
                    <div>{{ jawaban.point }} pts</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #cell(soal)="data">
            <div
              v-html="data.item.question"
              style="
                max-width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              "
            ></div>
          </template>
          <template #cell(kode_soal)="data">
            {{ data.item.quetion_code }}
          </template>
          <template #cell(tipe)="data">
            <span
              class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
            >
              {{ data.item.type }}
            </span>
          </template>
          <template #cell(bobot)="data">
            <!--          <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
            <!--            {{ topik }}-->
            <!--          </b-badge>-->
            <span
              class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
            >
              {{ data.item.bobot }} pts
            </span>
          </template>
          <template #cell(topic)="data">
            <span
              class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
              v-for="topik in data.item.topic"
              :key="topik"
            >
              {{ topik }}
            </span>
          </template>
          <template #cell(action)="data">
            <span
              @click="data.toggleDetails"
              class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
            >
              <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
              <i
                :class="`ki ki-bold-arrow-${
                  data.detailsShowing ? 'up' : 'down mt-1'
                } icon-sm`"
              ></i>
            </span>
            <span
              @click="toggleAnswer(data)"
              class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
              >Daftar Jawaban</span
            >
            <span
              @click="chooseSoal(data.item)"
              :class="`btn ${
                isExist(data.item._id) ? 'btn-warning' : 'btn-info'
              } font-weight-bolder font-size-sm
                mr-1`"
            >
              {{ isExist(data.item._id) ? "Soal Dipilih" : "Pilih Soal" }}
            </span>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          class="my-0"
        ></b-pagination>
      </div>
      <b-modal
        size="lg"
        modal-lg
        v-model="modalBankSoal"
        hide-footer
        centered
        scrollable
        title="Daftar Bank Soal"
      >
        <div class="d-block">
          <b-row class="justify-content-end">
            <button
              @click="closeModalBank()"
              class="btn btn-secondary font-weight-bolder font-size-sm mr-2"
            >
              Batal
            </button>

            <button
              v-if="newQuestions.length > 0"
              class="btn btn-primary font-weight-bolder font-size-sm mr-2"
              @click="addQuestionToQuestionPack"
            >
              Simpan
            </button>
          </b-row>
          <div class="text-start">
            <div class="d-flex" style="gap: 1rem">
              <b-form-group
                class="mr-1 w-25"
                id="input-group-1"
                label="Kategori Soal:"
                label-for="input-1"
              >
                <div
                  class="spinner spinner-primary spinner-left"
                  v-if="isLoading"
                >
                  <input class="form-control mb-5" />
                </div>
                <b-form-select
                  v-else
                  id="per-page-select"
                  v-model="exam_type"
                  :options="getCustomFirstExamOptions('Pilih Kategori Soal')"
                  size="md"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="exam_type"
                class="mr-1 w-25"
                id="input-group-1"
                label="Topik Soal:"
                label-for="input-1"
              >
                <div
                  class="spinner spinner-primary spinner-left"
                  v-if="isLoading"
                >
                  <input class="form-control mb-5" />
                </div>
                <vue-select
                  ref="paketDetailTopics"
                  id="paketDetailTopics"
                  taggable
                  multiple
                  push-tags
                  placeholder="Pilih Topik Soal"
                  v-model="questionTopics"
                  :options="questionTopicsOptions"
                />
              </b-form-group>
            </div>
          </div>
          <br />
          <b-row class="justify-content-between">
            <b-col cols="auto">
              <b-form-group>
                <b-form-select
                  id="per-page-select"
                  v-model="perPageOutside"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="auto">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            :filter="filterOutside"
            @filtered="onFiltered"
            :items="currentBankListOutside.data"
            :fields="fields"
            :busy="isLoading"
            :options="[5, 10, 15, { value: 100, text: 'More' }]"
            show-empty
            class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
            responsive
          >
            <template #empty="scope">
              <h6 class="text-center">Tidak ada soal ditemukan</h6>
            </template>
            <template #emptyfiltered="scope">
              <h6 class="text-center">Tidak ada soal ditemukan</h6>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(index)="row">
              {{ perPageOutside * (currentPageOutside - 1) + (row.index + 1) }}
            </template>
            <template #row-details="row">
              <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                Soal:
              </div>
              <div v-html="row.item.question"></div>
              <div
                class="text-dark-75 font-weight-bolder mb-1 font-size-sm"
                v-if="!row.item.showOnlyQuestion"
              >
                Jawaban:
                <div class="jawaban">
                  <div
                    :class="`card card-custom card-stretch border shadow-none`"
                    v-for="(jawaban, i) in row.item.answer"
                    :key="`${i}`"
                  >
                    <div
                      class="card-body d-flex flex-row p-4 justify-content-between align-items-center"
                    >
                      <div v-html="jawaban.answer"></div>
                      <div>{{ jawaban.point }} pts</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(soal)="data">
              <div
                v-html="data.item.question"
                style="
                  max-width: 200px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                "
              ></div>
            </template>
            <template #cell(kode_soal)="data">
              {{ data.item.quetion_code }}
            </template>
            <template #cell(tipe)="data">
              <span
                class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
              >
                {{ data.item.type }}
              </span>
            </template>
            <template #cell(bobot)="data">
              <!--          <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
              <!--            {{ topik }}-->
              <!--          </b-badge>-->
              <span
                class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
              >
                {{ data.item.bobot }} pts
              </span>
            </template>
            <template #cell(topic)="data">
              <span
                class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
                v-for="topik in data.item.topic"
                :key="topik"
              >
                {{ topik }}
              </span>
            </template>
            <template #cell(type)="data">
              {{ data.item.type == "bool" ? "true / false" : data.item.type }}
            </template>
            <template #cell(action)="data">
              <span
                @click="data.toggleDetails"
                class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
              >
                <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                <i
                  :class="`ki ki-bold-arrow-${
                    data.detailsShowing ? 'up' : 'down mt-1'
                  } icon-sm`"
                ></i>
              </span>
              <span
                @click="toggleAnswer(data)"
                class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
                >Daftar Jawaban</span
              >
              <span
                @click="chooseSoalAdd(data.item)"
                :class="`btn ${
                  isExistNewQuestion(data.item._id) ? 'btn-warning' : 'btn-info'
                } font-weight-bolder font-size-sm
                mr-1`"
              >
                {{
                  isExistNewQuestion(data.item._id)
                    ? "Soal Dipilih"
                    : "Pilih Soal"
                }}
              </span>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPageOutside"
            :total-rows="totalRowsOutside"
            :per-page="perPageOutside"
            align="right"
            class="my-0"
          ></b-pagination>
        </div>
      </b-modal>
    </div>
    <b-modal
      v-model="modalJawaban"
      centered
      scrollable
      title="Pilihan Jawaban"
      hide-footer
    >
      <div class="jawaban">
        <div
          :class="`card card-custom card-stretch border ${
            jawaban.isCorrect
              ? 'bg-light-primary text-primary border-primary'
              : ''
          }`"
          v-for="(jawaban, i) in selectedSoal.answer"
          :key="`${selectedSoal._id}${i}`"
        >
          <div
            class="card-body d-flex flex-row p-4 justify-content-between align-items-center"
          >
            <div v-html="jawaban.answer"></div>
            <div>{{ jawaban.point }} pts</div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
  GET_BANK_SOAL,
  GET_BANK_SOAL_OUTSIDE,
} from "@/core/services/store/banksoal.module";
import { GET_TOPIC } from "@/core/services/store/soal.module";

import Swal from "sweetalert2";

import {
  STORE_PAKET,
  GET_ONE_PAKET,
  REMOVE_QUESTION,
  ADD_QUESTION,
} from "@/core/services/store/paket.module";

import { globalMixin } from "../../../core/mixin/globalMixin";

export default {
  name: "PaketDetail",
  mixins: [globalMixin],
  components: {},
  data() {
    return {
      exam_type: null,
      topic: [],
      currentForm: {},
      isLoading: false,
      currentPage: 1,
      currentPageOutside: 1,
      totalRows: 0,
      totalRowsOutside: 0,
      perPageOutside: 10,
      selectedSoal: {},
      perPage: 10,
      selectedMapel: null,
      modalJawaban: false,
      modalBankSoal: false,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "question_code",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "type",
          label: "Kategori",
          sortable: true,
        },
        {
          key: "topic",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      filterOutside: null,
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      questions: [],
      newQuestions: [],
      questionTopics: [],
      questionTopicsOptions: [],
    };
  },
  mounted() {
    // this.getData();
    this.getBankSoal();
    this.getTopic();
    // this.totalRows = this.currentPaket.questions.length;
  },
  methods: {
    removeQuestion() {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus Soal terpilih ?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            const current = {
              questions: this.questions,
              id: this.idQuestion,
            };
            this.$store
              .dispatch(REMOVE_QUESTION, current)
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus Soal`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
                this.questions = [];
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Hapus Soal`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
    resetSelected() {
      this.questions = [];
    },
    isExist: function (item) {
      if (this.questions.indexOf(item) !== -1) {
        return true;
      }

      return false;
    },
    isExistNewQuestion: function (item) {
      if (this.newQuestions.indexOf(item) !== -1) {
        return true;
      }

      return false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    submitForm() {
      this.currentForm.questions = this.questions;

      this.$store
        .dispatch(STORE_PAKET, this.currentForm)
        .then((response) => {
          Swal.fire({
            title: response.message,
            text: `Berhasil Membuat Paket Soal`,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.$router.push(`/paket-soal`);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Membuat Paket Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_PAKET, this.idQuestion)
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentPaket.questions.length;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    onReset() {},
    viewJawaban(item) {
      this.selectedSoal = item;
      this.modalJawaban = true;
    },
    chooseSoal(item) {
      if (this.questions.includes(item._id)) {
        this.questions.splice(this.questions.indexOf(item._id), 1);
      } else {
        this.questions.push(item._id);
      }
    },
    chooseSoalAdd(item) {
      if (this.newQuestions.includes(item._id)) {
        this.newQuestions.splice(this.questions.indexOf(item._id), 1);
      } else {
        this.newQuestions.push(item._id);
      }
    },
    addQuestionToQuestionPack() {
      const _data = {
        id: this.idQuestion,
        questions: this.newQuestions,
      };

      this.$store
        .dispatch(ADD_QUESTION, _data)
        .then((response) => {
          this.getData();
          this.$bvToast.toast(`Berhasil menambah soal`, {
            title: response.message,
            variant: "success",
            solid: true,
          });
          this.newQuestions = [];
          this.getData();
          this.modalBankSoal = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal menambah soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    openModal() {
      this.modalBankSoal = true;
    },
    closeModalBank() {
      this.newQuestions = [];
      this.modalBankSoal = false;
    },
    getBankSoal() {
      this.isLoading = true;
      const param = new URLSearchParams();
      param.append("limit", this.perPageOutside);
      param.append("page", this.currentPageOutside);
      param.append("question_pack_id", this.idQuestion);
      param.append("where['exam_categories']", this.exam_type);
      param.append("topic", this.questionTopics);

      this.$store
        .dispatch(GET_BANK_SOAL_OUTSIDE, param.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRowsOutside = this.currentBankListOutside.total;
          this.currentBankListOutside.data.forEach((row) => {
            this.$nextTick(() => {
              this.$set(row, "_showDetails", true);
              this.$set(row, "showOnlyQuestion", true);
            });
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    toggleQuestion(data) {
      this.$nextTick(() => {
        if (!data.item.showOnlyQuestion) {
          this.$set(data.item, "_showDetails", true);
        } else {
          this.$set(data.item, "_showDetails", !data.item._showDetails);
        }
        this.$set(data.item, "showOnlyQuestion", true);
      });
    },
    toggleAnswer(data) {
      this.selectedSoal = data.item;
      this.$nextTick(() => {
        if (data.item.showOnlyQuestion) {
          this.$set(data.item, "showOnlyQuestion", false);
        } else {
          this.$set(data.item, "showOnlyQuestion", true);
        }
      });
    },
    getTopic() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_TOPIC, {})
        .then((data) => {
          this.isLoading = false;
          this.questionTopicsOptions = data.data;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$bvToast.toast(err, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters([
      "currentPaket",
      "currentBankSoalList",
      "currentBankListOutside",
      "currentSelectedTopic",
    ]),
    idQuestion() {
      return this.$route.params.idQuestion;
    },
  },

  watch: {
    perPageOutside: function () {
      this.getBankSoal();
    },
    currentPageOutside: function () {
      this.getBankSoal();
    },
    exam_type: function () {
      this.getBankSoal();
    },
    currentPaket: function (newVal, oldVal) {
      if (this.currentPaket.questions) {
        this.totalRows = this.currentPaket.questions.length;
        this.currentPaket.questions.forEach((row) => {
          this.$nextTick(() => {
            this.$set(row, "_showDetails", true);
            this.$set(row, "showOnlyQuestion", true);
          });
        });
      }
    },
    currentSelectedTopic: function (newVal) {},
    questionTopics: function (newVal) {
      this.getBankSoal();
    },
  },
};
</script>

<style scoped>
/deep/ div > .vs__dropdown-toggle {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 5px;
  border: 1px solid #e4e6ef !important;
}
.floating-question {
  z-index: 9999999;
  position: fixed;
  top: 10px;
  right: 10px;
  width: 150px;
  height: 80px;
}
</style>
